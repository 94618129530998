/* eslint-disable max-len */
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { gapi } from 'gapi-script';
import {
  LoginAsync,
  SsoLoginAsync,
  selectLoginResponse,
  selectStatus,
  GoogleLoginAsync,
} from '../../app/reducers/LoginSlice';
import Button from '../../components/Button';
import { SpinnerCircular } from 'spinners-react';
import theme from '../../styles/colors';
import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import { Body, Caption1, LargeTitle, Caption2 } from '../../styles/typography';
// import { StaticImage } from 'gatsby-plugin-image';
import { FaGoogle, FaApple} from 'react-icons/fa';
import { GoogleLogin } from 'react-google-login';
import AppleLogin from 'react-apple-login';
import { routes } from '../../navigator/routes';
import Input from '../../components/Input';
import { setPageTitle } from '../../app/reducers/HeadSlice';
import { useFormik } from 'formik';
import { LoginValidate } from '../../util/functions';
import Whitelist from './components/Whitelist';
import { federatedSignIn } from '../../auth/cognitoAuth';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
// import RedirectUrl from '../../components/Redirect';
const StaticImage = styled.img`
position: absolute;
width: 100%;
height: 100%;
object-fit: cover;
`
const LoginImageWrapper = styled.div`
  position: fixed;
  width: 50vw;
  height: 100%;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const LoginFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  @media screen and (max-width: 800px) {
    margin-left: 0;
    width: 100vw;
  }
`;

const LoginFormContainer = styled.form`
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
  justify-content: stretch;
  padding: 48px 32px;
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
`;

const InputGroup = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 24px;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: rgb(${(props) => props.theme.colors.blue400});
`;

const StyledBody = styled(Body)`
  color: rgb(${(props) => props.theme.colors.neutral200});
  text-align: center;
`;

const DividerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 8px;
  padding: 0 8px;
  align-items: center;

  > ${Caption1} {
    text-transform: uppercase;
    color: rgb(${(props) => props.theme.colors.neutral200});
  }

  > div {
    height: 1px;
    background: rgb(${(props) => props.theme.colors.neutral100});
  }
`;

const ErrorMessage = styled(Caption1)`
  color: rgb(${(props) => props.theme.colors.error400});
  text-align: center;
  height: 1em;
  padding-bottom: 10px;
  display:${(props) => props.resultCode && props.resultCode !== 200 && props.status !== 'loading' ? 'block' : 'none'}
`;

const ForgotPassword = styled(Caption2)`
text-align: right;
margin-top: -20px;
color: #4C5367;
font-size: 13px;
text-decoration: none;
`

const Login = (props) => {
  const dispatch = useDispatch();
  const [isValid, setIsValid] = React.useState(true);

  const [val, setVal] = React.useState(Object.keys(localStorage));
  const idToken = localStorage.getItem('CognitoIdentityServiceProvider.4ufoedd4so0jq9j8fd7k3oroua.' + localStorage.getItem('CognitoIdentityServiceProvider.4ufoedd4so0jq9j8fd7k3oroua.LastAuthUser') + '.idToken');
  useEffect(() => {
    dispatch(setPageTitle('Book Editor - Login'));
  }, [dispatch]);
  const result = useSelector(selectLoginResponse);
  setTimeout(() => { setVal(Object.keys(localStorage)) }, [5000]);
  const status = useSelector(selectStatus);

  const clientId =
    '298180869348-jrojn16bpon304kl1ivkmo9523ntil8f.apps.googleusercontent.com';

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      if (process.env.REACT_APP_LOGIN_WHITELIST === 'TRUE') {
        if (Whitelist.includes(values.email)) {
          setIsValid(true);
          dispatch(LoginAsync(values));
        } else setIsValid(false);
      } else dispatch(LoginAsync(values));
    },
    validate: LoginValidate,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });
  let redirect;
  if (props.pathname) {
    redirect = decodeURIComponent(props.pathname);
  } else {
    redirect = routes.app.path;
  }

  const LoginWithGoogle = (provider) => {
    federatedSignIn(provider);
  };
  const GoogleLoginSuccess = (res) => {
    const Obj = {
      userDetails: {
        photo: res.profileObj.imageUrl,
        email: res.profileObj.email,
        familyName: res.profileObj.familyName,
        givenName: res.profileObj.givenName,
        name: res.profileObj.name,
        id: res.profileObj.googleId,
      },
      sso_type: 'Google',
    };
    dispatch(SsoLoginAsync(Obj));
  }
  const AppleLoginSuccess = (res) => {
    try {
      const JwtData = jwtDecode(res.authorization.id_token);
      const Obj = {
        userDetails: {
          email: JwtData.email,
          familyName: res.user ?  res?.user?.name?.lastName : '',
          givenName: res.user ?  res?.user?.name?.firstName : '',
          name: res.user ?  res?.user?.name?.firstName : '' + ' ' + res.user ?  res?.user?.name?.firstName : '',
          id: JwtData.sub,
        },
        sso_type: 'Apple',
      }
      dispatch(SsoLoginAsync(Obj));
    } catch(error) {
      console.log('apple error', error);
    }
   

  }
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);
  useEffect(() => {
    if (idToken) {
      dispatch(GoogleLoginAsync());
    }
  }, [dispatch, idToken])
  const GoogleLoginFailure = (res) => {
    console.log(res);
  };
  useEffect(() => {
    if (result && result.code === 200) {
      if (Cookies.get('location-redirect')) {
        window.location.assign(Cookies.get('location-redirect'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  if (Cookies.get('idToken')) {
    if (Cookies.get('location-redirect')) {
      window.location.assign(Cookies.get('location-redirect'));
    }
    return <></>;
  } else {
    return (
      <div className="login" data-testid="LoginPage">
        <LoginImageWrapper data-testid="LoginImage">
          <StaticImage
            src="https://images.unsplash.com/photo-1543075036-3d6ad4e7f7da?ixlib=rb-1.2.1&d=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1015&q=80"
            placeholder="blurred"
            loading="eager"
            alt={'Login Image'}
          />
        </LoginImageWrapper>
        <LoginFormWrapper data-testid="LoginForm">
          <LoginFormContainer onSubmit={formik.handleSubmit}>
            <LargeTitle style={{fontFamily:'Lora'}}>Login</LargeTitle>
            <InputGroup>
              {process.env.REACT_APP_NEW_API === 'TRUE' ? <Button onButtonClick={() => LoginWithGoogle('Google')}>
                <FaGoogle />
                Continue with Google
              </Button> : <GoogleLogin
                clientId={clientId}
                onSuccess={GoogleLoginSuccess}
                onFailure={GoogleLoginFailure}
                cookiePolicy={'single_host_origin'}
                render={(renderProps) => (
                  <Button
                    onButtonClick={renderProps.onClick}
                    data-testid="GoogleButton"
                  >
                    <FaGoogle />
                    Continue with Google
                  </Button>
                )}
              />}

            </InputGroup>
            <InputGroup>
            <AppleLogin
            clientId="com.mystoriesmatter.loginqa"
            redirectURI="https://login-qa.mystoriesmatter.com/"
            responseType="id_token"
            responseMode="fragment"
            usePopup={true}
            scope="name email"
            callback={AppleLoginSuccess}
            render={(renderProps) => (
              <Button
                onButtonClick={renderProps.onClick}
                data-testid="AppleButton"
              >
                <FaApple />
                Continue with Apple
              </Button>
            )}
             />
            </InputGroup>
            <InputGroup>
              <Input
                label="Email"
                placeholder="Enter your email..."
                name={'email'}
                errorMessage={formik.errors.email}
                data-testid="EmailField"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onValueChange={formik.handleChange}
                errorCondition={formik.touched.email && formik.errors.email}
              />
              <Input
                label="Password"
                placeholder="Enter your password..."
                name={'password'}
                type={'password'}
                data-testid="PasswordField"
                errorMessage={formik.errors.password}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onValueChange={formik.handleChange}
                errorCondition={
                  formik.touched.password && formik.errors.password
                }
              />
              <ForgotPassword
              as="a"
              href={process.env.REACT_APP_MSM_FORGOT_PASSWORD_URL}
              target="_blank"
              >
              Forgot password?
              </ForgotPassword>
            </InputGroup>
            <Button
              type="primary"
              disabled={status === 'loading' || !formik.isValid ? true : false}
              data-testid="LoginButton"
            >
              {status === 'loading' ? (
                <SpinnerCircular
                  size={24}
                  thickness={200}
                  speed={100}
                  color={`rgba(${theme.colors.white}, 1)`}
                  secondaryColor={`rgba( ${theme.colors.neutral600}, 1)`}
                />
              ) : (
                <>
                  Login <FiArrowRight />
                </>
              )}
            </Button>
            <ErrorMessage resultCode={result.code} status={status}>
              {
                result &&
                  result.code !== 200 &&
                  status !== 'loading' ? (
                  result.message
                ) : (
                  ' '
                )}
            </ErrorMessage>

            <StyledBody>
            Don't have an account yet?{' '}
              <StyledLink
              href={process.env.REACT_APP_MSM_SIGNUP_URL}
              target="_blank">
              Signup
              </StyledLink>
            </StyledBody>
          </LoginFormContainer>
        </LoginFormWrapper>
      </div>
    );
  }
};
export default Login;
